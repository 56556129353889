import {all} from 'redux-saga/effects';
import authenticationSagas from './authentication/saga';
import onboardingSagas from "./onboarding/saga";
import linksSagas from "./links/saga";

export default function* rootSaga(getState) {
    yield all([
        authenticationSagas(),
        onboardingSagas(),
        linksSagas(),
    ]);
}
