import {combineReducers} from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authentication from './authentication/reducer';
import onboarding from './onboarding/reducer';
import links from './links/reducer';


const reducers = combineReducers({
    menu,
    settings,
    authentication,
    onboarding,
    links,
});

export default reducers;
