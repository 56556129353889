import { MODEL_PATH } from "./constants";
//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_PATH =
	"custom-endpoints/company-users/create-company-and-associate-with-company-user";
export const COMPLETE_DATA_PATH = `custom-endpoints/${MODEL_PATH}/complete-data`;
// CUSTOM ACTIONS
// ##############################################################
