import jwt_decode from "jwt-decode";

export class Session {
    constructor() {
        this.sessionName = "ffb_session"; // famosos session name
        this.session = this.getSession();
    }

    setSession = (token) => {
        localStorage.setItem(this.sessionName, token);
    };

    getSession = () => {
        const token = localStorage.getItem(this.sessionName);
        return token ? this.jwtDecode(token) : null
    };

    removeSession = () => {
        localStorage.removeItem(this.sessionName);
        window.location.replace("/")
    };

    tokenExpired() {
        if (this.utcSecondsToDatetime(this.getSession().exp) <= new Date()) {
            this.removeSession();
            return true;
        }
        return false;
    }

    utcSecondsToDatetime = (utcSeconds) => {
        if (utcSeconds) {
            const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
            date.setUTCSeconds(utcSeconds);
            return date
        } else {
            return null
        }
    };

    jwtDecode(token) {
        try {
            if (token) {
                return jwt_decode(token)
            }
        } catch (e) {
            return null
        }
    }
}
