import {
	CREATE_COMPANY_AND_ASSOCIATE_WITH_USER,
	CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_SUCCESS,
	CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_ERROR,
	COMPLETE_DATA,
	COMPLETE_DATA_SUCCESS,
	COMPLETE_DATA_ERROR,
	RESET_COMPLETE_DATA_ERROR
} from "./constants";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const createCompanyAndAssociateWithUser = (payload, history) => ({
	type: CREATE_COMPANY_AND_ASSOCIATE_WITH_USER,
	payload: payload,
	history
});
export const createCompanyAndAssociateWithUserSuccess = (payload) => ({
	type: CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_SUCCESS,
	payload: payload
});
export const createCompanyAndAssociateWithUserError = (payload) => ({
	type: CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_ERROR,
	payload: payload
});

export const completeData = (payload, history) => ({
	type: COMPLETE_DATA,
	payload,
	history
});

export const completeDataSuccess = (payload) => ({
	type: COMPLETE_DATA_SUCCESS,
	payload
});

export const completeDataError = (payload) => ({
	type: COMPLETE_DATA_ERROR,
	payload
});
export const resetCompleteDataError = (payload) => ({
	type: RESET_COMPLETE_DATA_ERROR
});
// CUSTOM ACTIONS
// ##############################################################
