//
//####################################
// ROOT
export const ROOT_PATH = "/";
export const APP_ROOT_PATH = "/app";
// END ROOT
//####################################


//####################################
// LINKS PREVIEW
export const LINKS_PREVIEW = "/:username";
// END LINKS PREVIEW
//####################################


//####################################
// AUTHENTICATION
export const AUTHENTICATION_ROOT_PATH = `${APP_ROOT_PATH}/authentication`;
export const AUTHENTICATION_LOGIN_PATH = `${AUTHENTICATION_ROOT_PATH}/login`;
export const AUTHENTICATION_REGISTER_PATH = `${AUTHENTICATION_ROOT_PATH}/register`;
export const AUTHENTICATION_FORGOT_PASSWORD_PATH = `${AUTHENTICATION_ROOT_PATH}/forgot-password`;
export const AUTHENTICATION_FORGOT_PASSWORD_VALIDATE_SECURITY_CODE_PATH = `${AUTHENTICATION_FORGOT_PASSWORD_PATH}/validate-security-code`;
export const AUTHENTICATION_FORGOT_PASSWORD_UPDATE_PASSWORD_PATH = `${AUTHENTICATION_FORGOT_PASSWORD_PATH}/update-password`;
export const AUTHENTICATION_RESET_PASSWORD_PATH = `${AUTHENTICATION_ROOT_PATH}/reset-password`;
// END AUTHENTICATION
//####################################


//####################################
// ON-BOARDING
export const ON_BOARDING_ROOT_PATH = `${APP_ROOT_PATH}/onboarding`;
export const ON_BOARDING_COMPLETE_DATA_PATH = `${ON_BOARDING_ROOT_PATH}/complete-data`;
// END ON-BOARDING
//####################################


//####################################
// ACCOUNT_SETTINGS
export const ACCOUNT_SETTINGS_ROOT_PATH = `${APP_ROOT_PATH}/account-settings`;
export const ACCOUNT_DATA_PATH = `${APP_ROOT_PATH}/account-settings/account-data`;
// END ACCOUNT_SETTINGS
//####################################


//####################################
// FAQS
export const FAQS_ROOT_PATH = `${APP_ROOT_PATH}/faqs`;
// END FAQS
//####################################


//####################################
//ERRORS
export const ERROR_ROOT_PATH = `${APP_ROOT_PATH}/error`;
export const ERROR_NOT_FOUND_PATH = `${ERROR_ROOT_PATH}/not-found`;
// END ERRORS
// ####################################
