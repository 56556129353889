export const MODEL_PATH = "authentication";
//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants
export const LOGIN = MODEL_PATH + "_LOGIN";
export const LOGIN_SUCCESS = MODEL_PATH + "_LOGIN_SUCCESS";
export const LOGIN_ERROR = MODEL_PATH + "_LOGIN_ERROR";
export const REGISTER = MODEL_PATH + "_REGISTER";
export const REGISTER_SUCCESS = MODEL_PATH + "_REGISTER_SUCCESS";
export const REGISTER_ERROR = MODEL_PATH + "_REGISTER_ERROR";
export const RESET_RECOVER_PASSWORD = MODEL_PATH + "_RESET_RECOVER_PASSWORD";
export const RECOVER_PASSWORD = MODEL_PATH + "_RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS =
	MODEL_PATH + "_RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = MODEL_PATH + "_RECOVER_PASSWORD_ERROR";
export const VALIDATE_SECURITY_CODE = MODEL_PATH + "_VALIDATE_SECURITY_CODE";
export const VALIDATE_SECURITY_CODE_SUCCESS =
	MODEL_PATH + "_VALIDATE_SECURITY_CODE_SUCCESS";
export const VALIDATE_SECURITY_CODE_ERROR =
	MODEL_PATH + "_VALIDATE_SECURITY_CODE_ERROR";
export const UPDATE_PASSWORD = MODEL_PATH + "_UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = MODEL_PATH + "_UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = MODEL_PATH + "_UPDATE_PASSWORD_ERROR";
export const LOGOUT = MODEL_PATH + "_LOGOUT";
// CUSTOM ACTIONS
// ##############################################################
