import {LIST, LIST_ERROR, LIST_SUCCESS,} from "./constants";
import {NotificationManager} from "../../components/common/react-notifications";

const INIT_STATE = {
    // CRUD

    // CUSTOM ACTIONS
    list: {
        ownerData: {},
        links: [],
        errorData: {},
    },
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CRUD

        // CRUD
        //##############################################################

        //##############################################################
        // CUSTOM ACTIONS
        // TODO: Write here your custom cases
        case LIST:
            res = {...state};
            res.list = {
                ownerData: {},
                links: [],
                errorData: {},
            };
            res.list.loading = true;
            return res;
        case LIST_SUCCESS:
            res = {...state};
            res.list.ownerData = action.payload.ownerData;
            res.list.links = action.payload.links;
            res.list.errorData = {};
            return res;
        case LIST_ERROR:
            res = {...state};
            res.list.errorData = action.payload;
            NotificationManager.warning(
                res.list.errorData,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        // CUSTOM ACTIONS
        // ##############################################################
        default:
            return {...state};
    }
};
