import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import * as GTM from "./constants/GTM";

const App = React.lazy(() => import('./App' ));

// Initialize GTM
GTM.initialize();

ReactDOM.render(
    <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading"/>}>
            <App/>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);
serviceWorker.unregister();
