import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  createCompanyAndAssociateWithUserError,
  createCompanyAndAssociateWithUserSuccess,
  completeDataError,
  completeDataSuccess
} from "./actions";
import { ROOT_PATH } from "../../routing/paths";
import apiService from "../api_service";
import {
  CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_PATH,
  COMPLETE_DATA_PATH
} from "./paths";
import {
  CREATE_COMPANY_AND_ASSOCIATE_WITH_USER,
  COMPLETE_DATA
} from "./constants";
import { NotificationManager } from "../../components/common/react-notifications";
import { Session } from "../session";
//#######################
// LOGIN
export function* watchCreateCompanyAndAssociateWithUser() {
  yield takeEvery(
    CREATE_COMPANY_AND_ASSOCIATE_WITH_USER,
    createCompanyAndAssociateWithUser
  );
}

const createCompanyAndAssociateWithUserAsync = async (data) => {
  return new Promise(function (resolve, reject) {
    apiService({
      async: true,
      path: CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_PATH,
      method: "POST",
      params: null,
      body: data
    })
      .then((res) => resolve(res.data))
      .catch((err) =>
        reject(err.response ? err.response.data : { error: "Server Error" })
      );
  });
};

function* createCompanyAndAssociateWithUser({ payload, history }) {
  try {
    const loginData = yield call(
      createCompanyAndAssociateWithUserAsync,
      payload
    );
    if (loginData.status !== "ERROR") {
      yield put(createCompanyAndAssociateWithUserSuccess(loginData.token));
      history.push(ROOT_PATH);
    } else {
      yield put(createCompanyAndAssociateWithUserError(loginData.error));
    }
  } catch (error) {
    yield put(createCompanyAndAssociateWithUserError("Server Error"));
  }
}

// END LOGIN
//#######################
export function* watchCompleteData() {
  yield takeEvery(COMPLETE_DATA, completeDataSaga);

  function* completeDataSaga({ payload, history }) {
    try {
      const completeData = yield call(completeDataAsync, payload);
      if (completeData.status !== "OK") {
        throw completeData.error;
      }
      new Session().setSession(completeData.data.token);
      yield put(completeDataSuccess(completeData.data));
      history.push(ROOT_PATH);
    } catch (error) {
      yield put(completeDataError(error));
      NotificationManager.warning(error, "Oops!", 3000);
    }
  }
}

const completeDataAsync = async (body) => {
  try {
    const response = await apiService({
      async: true,
      path: COMPLETE_DATA_PATH,
      method: "POST",
      body
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

export default function* rootSaga() {
  yield all([
    fork(watchCreateCompanyAndAssociateWithUser),
    fork(watchCompleteData)
  ]);
}
