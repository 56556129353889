import {MODEL_PATH} from "./constants";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const LIST_PATH =
    "custom-endpoints/" + MODEL_PATH + "/public-list/";
export const SAVE_LINK_EVENT_PATH =
    "custom-endpoints/" + MODEL_PATH + "/save-link-event";
// CUSTOM ACTIONS
// ##############################################################
