import {LIST, LIST_ERROR, LIST_SUCCESS} from "./constants";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const list = (payload) => ({
    type: LIST,
    payload: payload,
});
export const listSuccess = (payload) => ({
    type: LIST_SUCCESS,
    payload: payload,
});
export const listError = (payload) => ({
    type: LIST_ERROR,
    payload: payload,
});
// CUSTOM ACTIONS
// ##############################################################
