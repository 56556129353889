import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    LOGIN,
    LOGOUT,
    REGISTER,
    RECOVER_PASSWORD,
    VALIDATE_SECURITY_CODE,
    UPDATE_PASSWORD
} from "./constants";
import { Session } from "../session";

import {
    loginError,
    loginSuccess,
    registerError,
    registerSuccess,
    recoverPasswordError,
    recoverPasswordSuccess,
    validateSecurityCodeError,
    validateSecurityCodeSuccess,
    updatePasswordSuccess,
    updatePasswordError
} from "./actions";
import {
    ON_BOARDING_COMPLETE_DATA_PATH,
    ROOT_PATH,
    AUTHENTICATION_FORGOT_PASSWORD_VALIDATE_SECURITY_CODE_PATH,
    AUTHENTICATION_FORGOT_PASSWORD_UPDATE_PASSWORD_PATH
} from "../../routing/paths";
import apiService from "../api_service";
import {
    LOGIN_PATH,
    REGISTER_PATH,
    RECOVER_PASSWORD_PATH,
    UPDATE_PASSWORD_PATH,
    VALIDATE_SECURITY_CODE_PATH
} from "./paths";
import { NotificationManager } from "../../components/common/react-notifications";

//#######################
// LOGIN
export function* watchLogin() {
    yield takeEvery(LOGIN, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
    return new Promise(function (resolve, reject) {
        apiService({
            async: true,
            path: LOGIN_PATH,
            method: "POST",
            params: null,
            body: { email, password }
        })
            .then((res) => resolve(res.data))
            .catch((err) =>
                reject(
                    err.response ? err.response.data : { error: "Server Error" }
                )
            );
    });
};

function* loginWithEmailPassword({ payload, history }) {
    const { email, password } = payload;
    try {
        const loginData = yield call(
            loginWithEmailPasswordAsync,
            email,
            password
        );
        if (loginData.status !== "ERROR") {
            const { token } = loginData.data;
            const session = new Session();
            session.setSession(token);
            yield put(loginSuccess(token));
            history.push(ROOT_PATH);
        } else {
            yield put(loginError(loginData.error));
        }
    } catch (error) {
        yield put(loginError(error.error || error));
    }
}

// END LOGIN
//#######################

//#######################
// REGISTER
export function* watchRegister() {
    yield takeEvery(REGISTER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (data) => {
    return new Promise(function (resolve, reject) {
        apiService({
            async: true,
            path: REGISTER_PATH,
            method: "POST",
            params: null,
            body: data
        })
            .then((res) => resolve(res.data))
            .catch((err) =>
                reject(
                    err.response ? err.response.data : { error: "Server Error" }
                )
            );
    });
};

function* registerWithEmailPassword({ payload, history }) {
    try {
        const register = yield call(registerWithEmailPasswordAsync, payload);
        if (register.status !== "ERROR") {
            const { token } = register.data;
            const session = new Session();
            session.setSession(token);
            yield put(registerSuccess(token));
            history.push(ON_BOARDING_COMPLETE_DATA_PATH);
        } else {
            yield put(registerError(register.error));
        }
    } catch (error) {
        yield put(registerError(error.error || error || "Server Error"));
    }
}

// END REGISTER
//#######################

//#######################
// RECOVER PASSWORD
export function* watchRecoverPassword() {
    yield takeEvery(RECOVER_PASSWORD, recoverPassword);
}

const recoverPasswordAsync = async (body) => {
    try {
        const response = await apiService({
            async: true,
            path: RECOVER_PASSWORD_PATH,
            method: "POST",
            params: null,
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : { error: "Error inesperado del servidor" };
    }
};

function* recoverPassword({ payload, history }) {
    try {
        const recoverPasswordData = yield call(recoverPasswordAsync, payload);
        if (recoverPasswordData.status !== "OK") {
            throw recoverPasswordData.error;
        }
        yield put(recoverPasswordSuccess(payload.email));
        history &&
            history.push(
                AUTHENTICATION_FORGOT_PASSWORD_VALIDATE_SECURITY_CODE_PATH
            );
    } catch (error) {
        yield put(recoverPasswordError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END RECOVER PASSWORD
//#######################
//#######################
// RECOVER PASSWORD
export function* watchValidateSecurityCode() {
    yield takeEvery(VALIDATE_SECURITY_CODE, validateSecurityCode);
}

const validateSecurityCodeAsync = async (body) => {
    try {
        const response = await apiService({
            async: true,
            path: VALIDATE_SECURITY_CODE_PATH,
            method: "POST",
            params: null,
            body
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : { error: "Error inesperado del servidor" };
    }
};

function* validateSecurityCode({ payload, history }) {
    try {
        const validateSecurityCodeData = yield call(
            validateSecurityCodeAsync,
            payload
        );
        if (validateSecurityCodeData.status !== "OK") {
            throw validateSecurityCodeData.error;
        }
        yield put(
            validateSecurityCodeSuccess(validateSecurityCodeData.data.token)
        );
        new Session().setSession(validateSecurityCodeData.data.token);
        history.push(AUTHENTICATION_FORGOT_PASSWORD_UPDATE_PASSWORD_PATH);
    } catch (error) {
        yield put(validateSecurityCodeError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}

// END RECOVER PASSWORD
//#######################
export function* watchUpdatePassword() {
    yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

const updatePasswordAsync = async (newPassword) => {
    try {
        const response = await apiService({
            async: true,
            path: UPDATE_PASSWORD_PATH,
            method: "PUT",
            body: { newPassword }
        });
        return response.data;
    } catch (error) {
        return error.response
            ? error.response.data
            : { error: "Error inesperado del servidor" };
    }
};

function* updatePassword({ payload, history }) {
    const { newPassword } = payload;
    try {
        const updatePasswordData = yield call(updatePasswordAsync, newPassword);
        if (updatePasswordData.status !== "OK") throw updatePasswordData.error;
        yield put(updatePasswordSuccess());
        new Session().setSession(updatePasswordData.data.token);
        history.push(ROOT_PATH);
    } catch (error) {
        yield put(updatePasswordError(error));
        NotificationManager.warning(error, "Oops!", 3000);
    }
}
//#######################
// LOGOUT
export function* watchLogout() {
    yield takeEvery(LOGOUT, logoutAsyc);
}

function* logoutAsyc({ history }) {
    try {
        new Session().removeSession();
        history.push(ROOT_PATH);
    } catch (error) {}
}

// END LOGOUT
//#######################

export default function* rootSaga() {
    yield all([
        fork(watchLogin),
        fork(watchRegister),
        fork(watchLogout),
        fork(watchRecoverPassword),
        fork(watchValidateSecurityCode),
        fork(watchUpdatePassword)
    ]);
}
