import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER,
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    RECOVER_PASSWORD,
    RESET_RECOVER_PASSWORD,
    RECOVER_PASSWORD_ERROR,
    RECOVER_PASSWORD_SUCCESS,
    VALIDATE_SECURITY_CODE,
    VALIDATE_SECURITY_CODE_ERROR,
    VALIDATE_SECURITY_CODE_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_ERROR
} from "./constants";
import { NotificationManager } from "../../components/common/react-notifications";
import {
    LOGIN_INITIAL_STATE,
    POST_INITIAL_STATE,
    REGISTER_INITIAL_STATE,
    RECOVER_PASSWORD_INITIAL_STATE,
    VALIDATE_SECURITY_CODE_INITIAL_STATE,
    UPDATE_PASSWORD_INITIAL_STATE
} from "../initial_states";

const INIT_STATE = {
    // CRUD

    // CUSTOM ACTIONS
    login: LOGIN_INITIAL_STATE,
    register: REGISTER_INITIAL_STATE,
    recoverPassword: RECOVER_PASSWORD_INITIAL_STATE,
    validateSecurityCode: VALIDATE_SECURITY_CODE_INITIAL_STATE,
    updatePassword: UPDATE_PASSWORD_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CRUD

        // CRUD
        //##############################################################

        //##############################################################
        // CUSTOM ACTIONS
        // TODO: Write here your custom cases
        case LOGIN:
            res = { ...state };
            res.login = { ...LOGIN_INITIAL_STATE };
            res.login.loading = true;
            return res;
        case LOGIN_SUCCESS:
            res = { ...state };
            res.login = { ...LOGIN_INITIAL_STATE };
            res.login.token = action.payload.token;
            return res;
        case LOGIN_ERROR:
            res = { ...state };
            res.login = { ...LOGIN_INITIAL_STATE };
            res.login.errorData = action.payload;
            NotificationManager.warning(
                res.login.errorData,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case REGISTER:
            res = { ...state };
            res.register = { ...REGISTER_INITIAL_STATE };
            res.register.loading = true;
            return res;
        case REGISTER_SUCCESS:
            res = { ...state };
            res.register = { ...REGISTER_INITIAL_STATE };
            res.register.token = action.payload.token;
            return res;
        case REGISTER_ERROR:
            res = { ...state };
            res.register = { ...REGISTER_INITIAL_STATE };
            res.register.errorData = action.payload;
            NotificationManager.warning(
                res.register.errorData,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        case RECOVER_PASSWORD:
            res = { ...state };
            res.recoverPassword = { ...RECOVER_PASSWORD_INITIAL_STATE };
            res.recoverPassword.loading = true;
            return res;
        case RECOVER_PASSWORD_SUCCESS:
            res = { ...state };
            res.recoverPassword = { ...RECOVER_PASSWORD_INITIAL_STATE };
            res.recoverPassword.recoveryEmail = action.payload;
            return res;
        case RECOVER_PASSWORD_ERROR:
            res = { ...state };
            res.recoverPassword = { ...RECOVER_PASSWORD_INITIAL_STATE };
            res.recoverPassword.errorData = action.payload;
            return res;
        case RESET_RECOVER_PASSWORD:
            res = { ...state };
            res.recoverPassword = { ...RECOVER_PASSWORD_INITIAL_STATE };
            return res;
        case VALIDATE_SECURITY_CODE:
            res = { ...state };
            res.validateSecurityCode = {
                ...VALIDATE_SECURITY_CODE_INITIAL_STATE
            };
            res.validateSecurityCode.loading = true;
            return res;
        case VALIDATE_SECURITY_CODE_SUCCESS:
            res = { ...state };
            res.validateSecurityCode = {
                ...VALIDATE_SECURITY_CODE_INITIAL_STATE
            };
            res.validateSecurityCode.token = action.payload;
            return res;
        case VALIDATE_SECURITY_CODE_ERROR:
            res = { ...state };
            res.validateSecurityCode = {
                ...VALIDATE_SECURITY_CODE_INITIAL_STATE
            };
            res.validateSecurityCode.errorData = action.payload;
            return res;
        case UPDATE_PASSWORD:
            res = { ...state };
            res.updatePassword = {
                ...UPDATE_PASSWORD_INITIAL_STATE,
                loading: true
            };
            return res;
        case UPDATE_PASSWORD_SUCCESS:
            res = { ...state };
            res.updatePassword = {
                ...UPDATE_PASSWORD_INITIAL_STATE,
                loading: false,
                data: action.payload
            };
            return res;
        case UPDATE_PASSWORD_ERROR:
            res = { ...state };
            res.updatePassword = {
                ...UPDATE_PASSWORD_INITIAL_STATE,
                errorData: action.payload
            };
            return res;
        case LOGOUT:
            return { ...state };
        // CUSTOM ACTIONS
        // ##############################################################
        default:
            return { ...state };
    }
};
