import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    RECOVER_PASSWORD,
    RESET_RECOVER_PASSWORD,
    RECOVER_PASSWORD_SUCCESS,
    RECOVER_PASSWORD_ERROR,
    VALIDATE_SECURITY_CODE,
    VALIDATE_SECURITY_CODE_SUCCESS,
    VALIDATE_SECURITY_CODE_ERROR,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_ERROR,
    LOGOUT
} from "./constants";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const login = (payload, history) => ({
    type: LOGIN,
    payload: payload,
    history
});
export const loginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload: payload
});
export const loginError = (payload) => ({
    type: LOGIN_ERROR,
    payload: payload
});
export const register = (payload, history) => ({
    type: REGISTER,
    payload: payload,
    history
});
export const registerSuccess = (payload) => ({
    type: REGISTER_SUCCESS,
    payload: payload
});
export const registerError = (payload) => ({
    type: REGISTER_ERROR,
    payload: payload
});
export const recoverPassword = (payload, history) => ({
    type: RECOVER_PASSWORD,
    payload,
    history
});
export const recoverPasswordSuccess = (payload) => ({
    type: RECOVER_PASSWORD_SUCCESS,
    payload
});
export const recoverPasswordError = (payload) => ({
    type: RECOVER_PASSWORD_ERROR,
    payload
});

export const resetRecoverPassword = () => ({
    type: RESET_RECOVER_PASSWORD
});

export const validateSecurityCode = (payload, history) => ({
    type: VALIDATE_SECURITY_CODE,
    payload: payload,
    history
});
export const validateSecurityCodeSuccess = (payload) => ({
    type: VALIDATE_SECURITY_CODE_SUCCESS,
    payload: payload
});
export const validateSecurityCodeError = (payload) => ({
    type: VALIDATE_SECURITY_CODE_ERROR,
    payload
});
export const updatePassword = (payload, history) => ({
    type: UPDATE_PASSWORD,
    payload,
    history
});

export const updatePasswordSuccess = (payload, history) => ({
    type: UPDATE_PASSWORD_SUCCESS,
    payload
});

export const updatePasswordError = (payload, history) => ({
    type: UPDATE_PASSWORD_ERROR,
    payload
});
export const logout = (history) => ({
    type: LOGOUT,
    payload: history
});
// CUSTOM ACTIONS
// ##############################################################
