export const MODEL_PATH = "on-boarding";
//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants
export const CREATE_COMPANY_AND_ASSOCIATE_WITH_USER =
	"CREATE_COMPANY_AND_ASSOCIATE_WITH_USER";
export const CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_SUCCESS =
	"CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_SUCCESS";
export const CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_ERROR =
	"CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_ERROR";

export const COMPLETE_DATA = MODEL_PATH + "_COMPLETE_DATA";
export const COMPLETE_DATA_SUCCESS = MODEL_PATH + "_COMPLETE_DATA_SUCCESS";
export const COMPLETE_DATA_ERROR = MODEL_PATH + "_COMPLETE_DATA_ERROR";
export const RESET_COMPLETE_DATA_ERROR =
	MODEL_PATH + "RESET_COMPLETE_DATA_ERROR";
// CUSTOM ACTIONS
// ##############################################################
