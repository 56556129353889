import {
    CREATE_COMPANY_AND_ASSOCIATE_WITH_USER,
    CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_ERROR,
    CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_SUCCESS,
    COMPLETE_DATA,
    COMPLETE_DATA_SUCCESS,
    COMPLETE_DATA_ERROR,
    RESET_COMPLETE_DATA_ERROR
} from "./constants";
import { NotificationManager } from "../../components/common/react-notifications";
import {
    CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_INITIAL_STATE,
    POST_INITIAL_STATE
} from "../initial_states";

const INIT_STATE = {
    // CRUD

    // CUSTOM ACTIONS
    createCompanyAndAssociateWithUser: {},
    completeData: POST_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CRUD

        // CRUD
        //##############################################################

        //##############################################################
        // CUSTOM ACTIONS
        // TODO: Write here your custom cases
        case CREATE_COMPANY_AND_ASSOCIATE_WITH_USER:
            res = { ...state };
            res.createCompanyAndAssociateWithUser = {
                ...CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_INITIAL_STATE
            };
            res.createCompanyAndAssociateWithUser.loading = true;
            return res;
        case CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_SUCCESS:
            res = { ...state };
            res.createCompanyAndAssociateWithUser = {
                ...CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_INITIAL_STATE
            };
            res.createCompanyAndAssociateWithUser.data = action.payload;
            return res;
        case CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_ERROR:
            res = { ...state };
            res.createCompanyAndAssociateWithUser = {
                ...CREATE_COMPANY_AND_ASSOCIATE_WITH_USER_INITIAL_STATE
            };
            res.createCompanyAndAssociateWithUser.errorData = action.payload;
            NotificationManager.warning(
                res.createCompanyAndAssociateWithUser.errorData.error,
                "Oops!",
                3000,
                null,
                null,
                ""
            );
            return res;
        // CUSTOM ACTIONS
        // ##############################################################
        case COMPLETE_DATA:
            res = { ...state };
            res.completeData = {
                ...POST_INITIAL_STATE,
                loading: true
            };
            return res;
        case COMPLETE_DATA_SUCCESS:
            res = { ...state };
            res.completeData = {
                ...POST_INITIAL_STATE,
                loading: false,
                data: action.payload
            };
            return res;
        case COMPLETE_DATA_ERROR:
            res = { ...state };
            res.completeData = {
                ...POST_INITIAL_STATE,
                errorData: action.payload
            };
            return res;
        case RESET_COMPLETE_DATA_ERROR:
            res = { ...state };
            res.completeData = {
                ...POST_INITIAL_STATE
            };
            return res;
        default:
            return { ...state };
    }
};
