export const defaultMenuType = "menu-sub-hidden";
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "es";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" }
];
export const searchPath = "/app/pages/search";
export const servicePath = "http://localhost:3002/api/";
export const isMultiColorActive = false;
export const defaultColor = "dark.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey = "LINKS__theme_color";
export const themeRadiusStorageKey = "LINKS__theme_radius";
export const isDemo = false;
