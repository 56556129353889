import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {LIST,} from "./constants";
import apiService from "../api_service";
import {LIST_PATH, SAVE_LINK_EVENT_PATH,} from "./paths";
import {listError, listSuccess} from "./actions";

//#######################
// LIST
export function* watchList() {
    yield takeEvery(LIST, listLinks);
}

const listLinksAsync = async (username) => {
    return new Promise(function (resolve, reject) {
        apiService({
            async: true,
            path: LIST_PATH + username,
            method: "GET",
            params: null,
        })
            .then((res) => resolve(res.data))
            .catch((err) =>
                reject(
                    err.response ? err.response.data : {error: "Server Error"}
                )
            );
    });
};

function* listLinks({payload}) {
    const {username} = payload;
    try {
        const listData = yield call(
            listLinksAsync,
            username,
        );
        if (listData.status !== "ERROR") {
            yield put(listSuccess(listData.data));
        } else {
            yield put(listError(listData.error));
        }
    } catch (error) {
        yield put(listError(error.error || error));
    }
}

// END LIST
//#######################


//#######################
// SAVE LINK EVENT
export const saveLinkEventAsync = async (data) => {
    return new Promise(function (resolve, reject) {
        apiService({
            async: true,
            path: SAVE_LINK_EVENT_PATH,
            method: "POST",
            body: data,
        })
            .then((res) => resolve(res.data))
            .catch((err) =>
                reject(
                    err.response ? err.response.data : {error: "Server Error"}
                )
            );
    });
};

// SAVE LINK EVENT
//#######################




export default function* rootSaga() {
    yield all([
        fork(watchList),
    ]);
}
